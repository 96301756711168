.map-container {
  width: 100%;
  height: 100%;
  max-width: calc(100% - 11px);
  max-height: calc(100% - 11px);
  top: 6px;
  left: 6px;
  border-radius: 3px;
}

.dark-mode {
  color: white;
  z-index: 3;
}

.dark-mode .mapboxgl-popup-content {
  background-color: #172839;
}
.dark-mode .mapboxgl-popup-tip {
  border-top-color: #172839 !important;
}

.mapboxgl-ctrl-group button {
  background: transparent !important;
  height: 23px !important;
  width: 23px !important;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: unset !important;
}

.mapboxgl-ctrl-group button:hover {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.mapboxgl-ctrl-group {
  background: transparent !important;
}

/* turns out they already had a fade in */
/* .mapboxgl-marker#custom {
  transition: none;
  color: #99CBE3
} */

.mapboxgl-marker {
  opacity: 1 !important;
}

@keyframes fadeIn {
  to {
    color: var(--color);
  }
}
