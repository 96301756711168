html {
  transition: all 0.5s linear;
  color-scheme: dark !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  transition: all 0.5s linear;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif !important;
  background: transparent;
  font-size: 14px !important;
}

.h6 {
  margin-bottom: 0px !important;
}

/*.mono-font {
  font-family: 'Roboto Mono' !important;
}*/

.tooltip {
  font-family: 'Roboto' !important;
  font-size: 13px !important;
}
.MuiChip-root {
  margin-right: 2px;
}

.plot-tooltip {
  font-family: 'Roboto' !important;
  font-size: 11px !important;
}

#quickfilter-multiple-checkbox-label[data-shrink='true'] {
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 10px;
}

div .anomPlotTransformWrapper {
  overflow: auto !important;
}
