@import '~ag-grid-community/src/styles/mixins/ag-grid-mixins';
@import '~ag-grid-community/src/styles/structural/common-structural';
@import '~ag-grid-community/src/styles/structural/charts-structural';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import '~ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin';
/**

===============

Avoid updating scss properties. Instead try to use the styled-component, AgGridCustom.tsx
However in some cases, ag-grid has specific scss properties that we can only override via scss

===============


*/
// This one handles range selection and gives them the proper background color
.ag-theme-alpine-dark
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  background-color: rgba(68, 118, 194, 0.85) !important;
}
//these four handle cells also in selected rows. so they dont double highlight
.ag-theme-alpine-dark .ag-cell-range-selected-1:not(.ag-cell-focus),
.ag-theme-alpine-dark
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1 {
  background-color: rgba(68, 118, 194, 0.85) !important;
}
.ag-theme-alpine-dark .ag-cell-range-selected-2:not(.ag-cell-focus),
.ag-theme-alpine-dark
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-2 {
  background-color: rgba(68, 118, 194, 0.85) !important;
}

.ag-theme-alpine-dark .ag-cell-range-selected-3:not(.ag-cell-focus),
.ag-theme-alpine-dark
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-3 {
  background-color: rgba(68, 118, 194, 0.85) !important;
}

.ag-theme-alpine-dark .ag-cell-range-selected-4:not(.ag-cell-focus),
.ag-theme-alpine-dark
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-4 {
  background-color: rgba(68, 118, 194, 0.85) !important;
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      row-height: 21px,
      // header-height: 24px,
      cell-horizontal-border: solid #181d1f,
    )
  );
}

.ag-theme-alpine-dark {
  @include ag-theme-alpine-dark(
    (
      row-height: 21px,
      // header-height: 24px,
      cell-horizontal-border: solid #66757f,
      header-background-color: #172839,
      foreground-color: #b9bfc4,
    )
  );
}
