.card {
  border-radius: 2px;
}

.card .header .button-group {
  display: flex;
}

.card .header .button {
  font-size: 20px;
  margin-left: 2px;
  margin-right: 2px;
  align-self: center;
  border-radius: 5px;
  cursor: pointer;
}

.card .header .handle-icon {
  font-size: 20px;
  font-weight: 100;
  align-self: center;
}

.card .card-wrapper {
  margin: 5px;
  height: 100%;
}

.card .content {
  /* padding-bottom: 10px; */
  margin-bottom: 5px;
  height: calc(98% - 10px);
  overflow: auto;
  /* width:99%; */
}

.card .tabbed-content {
  overflow: hidden;
  height: 98%;
}

.body {
  cursor: default !important;
}

/* https://github.com/react-grid-layout/react-grid-layout/issues/171 */
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
