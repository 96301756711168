#editor-container {
  width: 98%;
  height: calc(98% - 24px);
}

#editor-container section#editor {
  width: 98% !important;
  height: calc(98% - 24px) !important;
  position: unset;
  overflow: unset;
}

#editor-container section#editor div#canvasContainer {
  width: 98% !important;
  height: calc(95% - 56px) !important;
  overflow: hidden;
}

.canvasContainer {
  min-width: unset !important;
  background: transparent;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
  overflow: scroll !important;
}

.transform-component-module_content__2jYgh {
  width: unset !important;
  height: unset !important;
}

#editor-container section#editor .canvas {
  position: unset !important;
}

.zoomOptions {
  text-align-last: right;
  margin-right: 3%;
  float: right;
}

.card.rule-output .content {
  overflow: hidden !important;
}

.selectRunText {
  color: gray !important;
  float: left;
}
.ruleCardWarningMsg {
  font-size: 1.17em !important;
  margin-left: 4px !important;
  color: #6c757d !important;
}
